.appHeader {
  width: 100%;
  font-family: "Roboto Slab", "PT Serif", serif;
  font-size: 22pt;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 2; }

@media (max-width: 575px) {
  .appHeader {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }
  .appHeaderNav {
    align-self: center;
    padding-left: 0; }
  .appHeaderContent {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem; } }

@media (min-width: 575px) and (max-width: 768px) {
  .appHeader {
    text-align: right; }
  .appHeaderContent {
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem; } }

@media (min-width: 768px) {
  .appHeader {
    text-align: right; }
  .appHeaderContent {
    padding-top: 2rem;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto; } }

.appHeaderTitle {
  font-weight: bold;
  color: #212121;
  text-decoration: none;
  float: left; }
  .appHeaderTitle:hover {
    color: #000; }

.appHeaderNav ul {
  list-style-type: none;
  display: inline;
  padding-left: 0; }
  .appHeaderNav ul li {
    display: inline; }
