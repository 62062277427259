.content span {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 16pt; }

.content p {
  margin-top: 2rem; }

.content a, .content Link {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 16pt;
  font-weight: bold; }
  .content a:hover, .content Link:hover {
    color: #c41c00; }

.content h1 {
  color: #000;
  font-family: "Roboto Slab", "PT Serif", serif;
  font-size: 26pt; }

.content h2 {
  color: #000;
  font-family: "Roboto Slab", "PT Serif", serif;
  font-size: 24pt; }

.content h3 {
  color: #000;
  font-family: "Roboto Slab", "PT Serif", serif;
  font-size: 22pt; }

.content h4 {
  color: #000;
  font-family: "Roboto Slab", "PT Serif", serif;
  font-size: 20pt; }

.content h5 {
  color: #000;
  font-family: "Roboto Slab", "PT Serif", serif;
  font-size: 18pt; }

.content h6 {
  color: #000;
  font-family: "Roboto Slab", "PT Serif", serif;
  font-size: 16pt; }

@media (min-width: 768px) {
  .content {
    padding-top: 5rem;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 575px) and (max-width: 768px) {
  .content {
    padding-top: 5rem;
    padding-left: 4rem;
    padding-right: 4rem; } }

@media (max-width: 575px) {
  .content {
    padding-top: 5rem;
    padding-left: 1rem;
    padding-right: 1rem; } }
