@media (min-width: 768px) {
  .projectCard {
    height: 30rem; } }

.projectCard {
  width: 19.5rem;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: #fff;
  transition: 0.2s;
  position: relative;
  z-index: 0; }
  .projectCard:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    z-index: 1;
    transition: 0.2s; }
  .projectCard a {
    text-decoration: none; }
  .projectCard img {
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
    height: 10rem;
    background: #fafafa; }
  .projectCard .projectCardText {
    padding: 1rem; }
    .projectCard .projectCardText p {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      text-align: left; }
  .projectCard .projectTitle {
    font-size: 16pt;
    font-family: "Roboto Slab", "PT Serif", serif; }
  .projectCard .projectDescription {
    font-size: 12pt;
    font-weight: normal; }
  .projectCard .projectTechnologiesContainer {
    width: 100%;
    padding: 1rem;
    background: #fafafa;
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    bottom: 0;
    text-align: left; }
    .projectCard .projectTechnologiesContainer span {
      font-size: 12pt;
      font-weight: normal;
      float: left; }
  @media (max-width: 768px) {
    .projectCard .projectTechnologiesContainer {
      position: relative;
      display: flex; } }
