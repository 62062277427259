.navLinkContainer:first-child {
  margin-right: 0.5rem; }

.navLink {
  text-decoration: none;
  color: #212121;
  transition: 0.1s;
  outline: 0; }
  .navLink:hover {
    color: #000; }

@media (max-width: 575px) {
  .navLink {
    font-size: 18pt; } }

.currentNavLink {
  color: #000;
  border-bottom: 2px solid #ff5722; }
