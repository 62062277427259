.profilePicContainer {
  height: 10em;
  width: 10em;
  margin-right: 1rem; }
  .profilePicContainer img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: #fafafa; }

@media (min-width: 575px) {
  .profilePicContainer {
    float: left; } }

@media (max-width: 575px) {
  .profilePicContainer {
    margin: auto; } }
